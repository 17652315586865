export const CONSTANTE_MARCAS_MODULO =  [
    {label:"Android/iOS", data:"Mobile"},
    {label:"Calamp", data:"Calamp"},
    {label:"CCTR", data:"CCTR"},
    {label:"Continental", data:"Continental"},
    {label:"Concox", data:"GT"},			
    {label:"E3", data:"E3"},
    {label:"GuardianCar", data:"GuardianCar"},
    {label:"Globalstar", data:"Globalstar"},
    {label:"Globalstar/Multiportal", data:"GlobalstarMultiportal"},    
    {label:"Multiportal", data:"Multiportal"},    
    {label:"Global Position", data:"GlobalPosition"},
    {label:"JIMI", data:"JIMI" }, 
    {label:"JIMI HUB", data:"JIMI_HUB" }, 
    {label:"Jointech", data:"Jointech"},
    {label:"Kingneed", data:"Kingneed"},
    {label:"Magneti Marelli", data:"MagnetiMarelli"},
    {label:"Maxtrack", data:"Maxtrack"},
    {label:"Maxtrack MTC", data:"MaxtrackMTC"},
    {label:"Maxtrack MTC 500", data:"MaxtrackMTC500"},
    {label:"Queclink", data:"Queclink"},
    {label:"Skywave IDP-280 + MTC700", data:"MaxtrackMTCReduced"},
    {label:"STI", data:"STI"},
    {label:"Suntech", data:"Suntech"},
    {label:"TLT", data:"TLT"},
    {label:"Teltonika", data:"Teltonika"},    
    {label:"Virtec", data:"Virtec"},
    {label:"Zenite", data:"Zenite"},
    {label:"Gps Tracker (Inativo)", data:"HJDKAP"}
];

export const CONSTANTE_MARCA_MODELOS_MODULOS = [ 
    {label:"Android/iOS", data:"Mobile", 
          modelos: [{label:"Celular", data:"Celular"}, {label:"Tablet", data:"Tablet"}, {label:"PORTATIL", data:"PORTATIL"},]},
    {label:"Calamp", data:"Calamp", 
          modelos: [{label:"Calamp LMU-800", data:"Calamp"}]},
    {label:"CCTR", data:"CCTR", 
          modelos: [{label:"CCTR-801B", data:"CCTR-801B"}, {label:"CCTR-620+", data:"CCTR-620+"}]},
    {label:"Continental", data:"Continental", 
          modelos: [ {label:"Rastrear LIGHT QB", data:"Rastrear Light QB"}, {label:"Rastrear LIGHT QB Full", data:"Rastrear QB Full"}, {label:"VDO Rastrear Evolution", data:"VDO Evolution"},]},
    {label:"Concox", data:"GT", 
          modelos: [{label:"Concox AT4", data:"AT4"}, {label:"Concox CRX1", data:"CRX1"}, {label:"Concox CRX1 New", data:"CRX1New"},  {label:"Concox CRX3", data:"CRX3"}, {label:"Concox CRX3 New", data:"CRX3New"}, {label:"Concox GT300", data:"GT300"}, {label:"GT06", data:"GT06"}, 
            {label:"J16", data:"J16"}, {label:"Nonus N4", data:"Nonus N4"}, {label:"Nonus N2", data:"Nonus N2"}]},
    {label:"E3", data:"E3", 
          modelos: [{label:"E3", data:"E3"}]},
    {label:"GuardianCar", data:"GuardianCar", 
          modelos: [{label:"GuardianCar SAT-401E", data:"SAT-401E"}]},
    {label:"Globalstar", data:"Globalstar", 
          modelos: [{label:"Globalstar Spot Trace", data:"Spot Trace"}, {label:"Globalstar Smartone C", data:"Smartone C"}]},
    {label:"GlobalstarMultiportal", data:"GlobalstarMultiportal", modelos: [{label:"Globalstar/Multiportal", data: "Globalstar/Multiportal"}]},          
    {label:"Global Position", data:"GlobalPosition", 
          modelos: [{label:"GP-900M", data:"GP-900M"}]},
    {label:"Jointech", data:"Jointech", 
          modelos: [{label:"Jointech GP4000", data:"GP4000"}]},
    {label:"JIMI", data:"JIMI", 
          modelos: [{label:"JC400D", data:"JC400D"}]},
    {label:"JIMI HUB", data:"JIMI_HUB", 
          modelos: [{label:"JC400D", data:"JC400D"}, {label:"JC450", data:"JC450"}, {label:"JC400AD", data:"JC400AD"} , {label:"JC400P", data:"JC400P"}]},
    {label:"Kingneed", data:"Kingneed", modelos: [{label:"TK20SE", data: "TK20SE"}]},          
    {label:"Magneti Marelli", data:"MagnetiMarelli", 
          modelos: [{label:"Magnet Marelli TBOX HW06", data:"TBOX HW06"}]},
    {label:"Maxtrack", data:"Maxtrack", 
          modelos: [ {label:"MXT 100 +", data:"MXT 100 +"}, {label:"MXT 130", data:"MXT 130"}, {label:"MXT 132", data:"MXT 132"}, 	{label:"MXT 140A", data:"MXT 140A"}, {label:"MXT 140B", data:"MXT 140B"}, {label:"MXT 141A", data:"MXT 141A"}, 	{label:"MXT 150F+", data:"MXT 150F+"}, 	{label:"MXT 150E+", data:"MXT 150E+"}, {label:"MXT 151A+", data:"MXT 151A+"}, {label:"MXT 151B+", data:"MXT 151B+"}, {label:"MXT 151D+", data:"MXT 151D+"}, {label:"MXT 160", data:"MXT 160"}, {label:"MXT 162", data:"MXT 162"}]},
    {label:"Maxtrack MTC", data:"MaxtrackMTC",
          modelos: [ {label:"MTC 700 Adv", data:"MTC 700 Adv"}]},
    {label:"Maxtrack MTC 500", data:"MaxtrackMTC500",
          modelos: [ {label:"MTC 500", data:"MTC 500"}]},
    {label:"Multiportal", data:"Multiportal", modelos: [{label:"RST-MINI-4G", data: "RST-MINI-4G"}]},  
    {label:"Queclink", data:"Queclink",
          modelos: [ {label:"Queclink GV50", data:"GV50"}, {label:"Queclink GV57", data:"GV57"}, {label:"Queclink GV55", data:"GV55"}, {label:"Queclink GV75", data:"GV75"}, {label:"Queclink GV75MG", data:"GV75MG"}, 
           {label:"Queclink GV300", data:"GV300"}, {label:"Queclink GV300CAN", data:"GV300CAN"}, {label:"Queclink GL320MG", data:"GL320MG"}, {label:"Queclink GL300", data:"GL300"}, {label:"Queclink GV500", data:"GV500"}, 
           {label:"Queclink GV57MG", data:"GV57"}, {label:"Queclink GV350MG", data:"GV350MG"}, {label:"Queclink GV350CEU", data:"GV350CEU"}, {label:"Queclink GV56", data:"GV56"}, {label:"Queclink GV58Ceu(CG)", data:"GV58CEU"}, {label:"Queclink GV57CG", data:"GV57CG"}, {label:"Queclink GV50CG", data:"GV50CG"} ]},
    {label:"Skywave IDP-280 + MTC700", data:"MaxtrackMTCReduced", modelos: [{label:"Skywave IDP-280 + MTC700", data:"MaxtrackMTCReduced"}]},
    {label:"STI", data:"STI", modelos: [{label:"PORTATIL", data:"PORTATIL"}]},
    {label:"Suntech", data:"Suntech", 
          modelos: [   {label:"ST 215", data:"ST 215"}, {label:"ST 240", data:"ST 240"}, {label:"ST 300R", data:"ST300R"}, {label:"ST 300H", data:"ST300H"}, {label:"ST 310U", data:"ST 310U"}, {label:"ST 340", data:"ST 340"}, {label:"ST 340 RB", data:"ST 340 RB"}, {label:"ST 340 UR", data:"ST 340 UR"}, {label:"ST 350", data:"ST 350"}, {label:"ST 350 LC2", data:"ST 350 LC2"}, {label:"ST 350 LC4", data:"ST 350 LC4"}, {label:"ST 400", data:"ST 400"}, {label:"ST 410G", data:"ST 410G"}, {label:"ST 440", data:"ST 440"}, {label:"ST 940 ", data:"ST 940"}, {label:"ST 70 ", data:"ST 70"},
          {label:"ST 310UC2", data:"ST 310UC2"}, {label:"ST 310ULC", data:"ST 310ULC"}, {label:"ST 4315U", data:"ST 4315U"} , {label:"ST 4305U", data:"ST 4305U"}, {label:"ST 8310U", data:"ST 8310U"} , {label:"ST 8310UM", data:"ST 8310UM"}, {label:"ST 8300", data:"ST 8300"}]},
    {label:"TLT", data:"TLT", modelos: [{label:"TLT - 1C", data:"TLT - 1C"}, {label:"TLT - 2N", data:"TLT - 2N"}]},
    {label:"Teltonika", data:"Teltonika", modelos: [{label:"FMB 140", data: "FMB 140"}, {label:"FMB 920", data: "FMB 920"}, {label:"FMC 920", data: "FMC 920"}, {label:"FMC 130", data: "FMC 130"}, {label:"FMC 150", data: "FMC 150"}, {label:"FMC 125", data: "FMC 125"}]},    
    {label:"Virtec", data:"Virtec", modelos: [{label:"Virtec Virloc VL6", data:"VIRLOC 6"}, {label:"Virtec Virloc VL7", data:"VIRLOC 7"},  {label:"Virtec Virloc VL8", data:"VIRLOC 8"},  {label:"Virtec Virloc 10", data:"VIRLOC 10"}, {label:"Virtec Virloc VL12", data:"VIRLOC 12"}]}					,  
];
                    
export const CONSTANTE_STATE_MODULO = [	
    {label:"ATIVO", data:"ATIVO"},
    {label:"COM TECNICO", data:"COM TECNICO"},
    {label:"COM CLIENTE", data:"COM CLIENTE"},
    {label:"DE TERCEIRO", data:"DE TERCEIRO"},
    {label:"EM ASSISTENCIA", data:"EM ASSISTENCIA"},
    {label:"EM ESTOQUE", data:"EM ESTOQUE"},    
    {label:"ENVIAR P/ REPARO", data:"ENVIAR P/ REPARO"},
    {label:"INATIVO", data:"INATIVO"},
    {label:"PERDIDO", data:"PERDIDO"},			 
    {label:"QUEBRADO", data:"QUEBRADO"},
    {label:"QUEIMADO", data:"QUEIMADO"},
    {label:"ROUBADO", data:"ROUBADO"},    
    {label:"RESSARCIDO", data:"RESSARCIDO"}    
];

export const CONSTANTE_TIPO_CLIENTE_PROPRIETARIO = [
	{label:"CLIENTE", data:"CLIENTE"},
	{label:"PROPRIETARIO", data:"PROPRIETARIO"}
];

export const CONSTANTE_STATUS_CLIENTE = [
    {label:"ATIVO", data:"ATIVO"},
    {label:"INATIVO", data:"INATIVO"},
    {label:"BLOQUEADO", data:"BLOQUEADO"},
    {label:"INADIMPLENTE", data:"INADIMPLENTE"}
];

export const CONSTANTE_OPERADORAS_GSM = [
    {label:"ALGAR", data:"ALGAR"},
    {label:"ALGAR-VOXTER", data:"ALGAR-VOXTER"},
    {label:"ALGAR-VIRTUEYES", data:"ALGAR-VIRTUEYES"},
    {label:"ALLCOM ALGAR", data:"ALLCOM-ALGAR"},    
    {label:"ALLCOM CLARO", data:"ALLCOM-CLARO"},
    {label:"ALLCOM VIVO", data:"ALLCOM-VIVO"},    
    {label:"ARQIA", data:"ARQIA"},
    {label:"ARQIA CLARO", data:"ARQIA-CLARO"},
    {label:"ARQIA VIVO", data:"ARQIA-VIVO"},
    {label:"CLARO", data:"CLARO"},
    {label:"ESEYE", data:"ESEYE"},
    {label:"ENTEL", data:"ENTEL"},
    {label:"EMnify", data:"EMNIFY"},			 
    {label:"LINK SOLUTIONS", data:"LINKSOLUTIONS"},
    {label:"LINK SOLUTIONS-VIVO", data:"LINKSOLUTIONS-VIVO"},
    {label:"LINK SOLUTIONS-CLARO", data:"LINKSOLUTIONS-CLARO"},
    {label:"SATÉLITE", data:"SAT"},
    {label:"TIM", data:"TIM"},
    {label:"OI", data:"OI"},
    {label:"TM DATA-VIVO", data:"TMDATA-VIVO"},
    {label:"TM DATA-CLARO", data:"TMDATA-CLARO"},
    {label:"TNS-VIVO", data:"TNS-VIVO"},
    {label:"TNS-CLARO", data:"TNS-CLARO"},
    {label:"VIVO", data:"VIVO"},
    {label:"VIRTUEYES-VIVO", data:"VIRTUEYES-VIVO"},
    {label:"VIRTUEYES-CLARO", data:"VIRTUEYES-CLARO"}
];

export const CONSTANTE_TIPO_CLIENTE = [
	{label:"PJ", data:"PJ"},
	{label:"PF", data:"PF"}
];

export const CONSTANTE_UFS = [
      {data:"AC", label:"Acre"},
      {data:"AL", label:"Alagoas"},
      {data:"AM", label:"Amazonas"},
      {data:"AP", label:"Amapá"},
      {data:"BA", label:"Bahia"},
      {data:"CE", label:"Ceará"},
      {data:"DF", label:"Distrito Federal"},
      {data:"ES", label:"Espirito Santo"},
      {data:"GO", label:"Goiás"},
      {data:"MA", label:"Maranhão"},
      {data:"MG", label:"Minas Gerais"},
      {data:"MS", label:"Mato Grosso do Sul"},
      {data:"MT", label:"Mato Grosso"},
      {data:"PA", label:"Pará"},
      {data:"PB", label:"Paraíba"},
      {data:"PE", label:"Pernambuco"},
      {data:"PI", label:"Piauí"},
      {data:"PR", label:"Paraná"},
      {data:"RJ", label:"Rio de Janeiro"},
      {data:"RN", label:"Rio Grande do Norte"},
      {data:"RO", label:"Rondônia"},
      {data:"RR", label:"Roraima"},
      {data:"RS", label:"Rio Grande do Sul"},
      {data:"SC", label:"Santa Catarina"},
      {data:"SE", label:"Sergipe"},
      {data:"SP", label:"São Paulo"},
      {data:"TO", label:"Tocantins"}
];

export const CONSTANTE_TIPO_VERSOES = [
      {label:"Basic", data:"BASIC"},
      {label:"Pro", data:"PRO"}
];

export const CONSTANTE_TIPO_CONTRATO_TESTE = [
      {label:"Contrato", data:"contrato"},
      {label:"Teste", data:"teste"}
];

export const CONSTANTE_STATUS_CONTRATO = [
      {label:"Ativo", data:"ATIVO"},
      {label:"Inativo", data:"INATIVO"},
      {label:"Cancelado", data:"CANCELADO"},
      {label:"Finalizado", data:"FINALIZADO"},
      {label:"Não Iniciado", data:"NAO_INICIADO"}
];

export const CONSTANTE_TIPO_CONTRATO = [   
      {label:"Mensalidade", data:"MENSALIDADE"},
      {label:"PRÉ-PAGO", data:"PREPAGO"}
];

export const CONSTANTE_ACAO_FIM_TESTE_CLIENTE = [         
      {label:"Finalizar Teste", data:"FINALIZAR_TESTE"},
      {label:"Alterar plano para BASIC", data:"ALTERAR_PLANO_PARA_BASIC"},
      {label:"Alterar plano para PRO", data:"ALTERAR_PLANO_PARA_PRO"}
];

export const CONSTANTE_STATUS_TESTE_CLIENTE = [         
      {label:"Desabilitado", data:"DESABILITADO"},
      {label:"Habilitado", data:"HABILITADO"}
];

export const CONSTANTE_TIPOS_AVISO =[   
      {labelTipoAviso:"Abertura Capô", dataTipoAviso:"AberturaCapo", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Fechamento Capô", dataTipoAviso:"FechamentoCapo", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Abertura Porta-Malas", dataTipoAviso:"AberturaPortaMalas", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Fechamento Porta-Malas", dataTipoAviso:"FechamentoPortaMalas", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Abertura Porta", dataTipoAviso:"AberturaPorta", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Fechamento Porta", dataTipoAviso:"FechamentoPorta", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Abertura Cofre", dataTipoAviso:"AberturaCofre", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Fechamento Cofre", dataTipoAviso:"FechamentoCofre", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Acionamento Engate", dataTipoAviso:"AcionamentoEngate", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Desacionamento Engate", dataTipoAviso:"FechamentoEngate", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Acionamento Caçamba", dataTipoAviso:"AcionamentoCacamba", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Desacionamento Caçamba", dataTipoAviso:"DesacionamentoCacamba", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Câmara Fria Ligada", dataTipoAviso:"CamaraFriaLigada", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Câmara Fria Desligada", dataTipoAviso:"CamaraFriaDesligada", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},	
      {labelTipoAviso:"Abertura Tampa de Combustível", dataTipoAviso:"AberturaTanqueCombustivel", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Fechamento Tampa de Combustível", dataTipoAviso:"FechamentoTanqueCombustivel", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Abertura Tampa de Combustível Tanque 1", dataTipoAviso:"AberturaTanque1Combustivel", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Fechamento Tampa de Combustível Tanque 1", dataTipoAviso:"FechamentoTanque1Combustivel", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Abertura Tampa de Combustível Tanque 2", dataTipoAviso:"AberturaTanque2Combustivel", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Fechamento Tampa de Combustível Tanque 2", dataTipoAviso:"FechamentoTanque2Combustivel", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},      
      {labelTipoAviso:"Excesso Velocidade", dataTipoAviso:"ExcessoVelocidade", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Médio Velocidade", dataTipoAviso:"ExcessoMedioVelocidade", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Grave Velocidade", dataTipoAviso:"ExcessoGraveVelocidade", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Velocidade Na Chuva", dataTipoAviso:"ExcessoVelocidadeNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Médio Velocidade Na Chuva", dataTipoAviso:"ExcessoMedioVelocidadeNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Grave Velocidade Na Chuva", dataTipoAviso:"ExcessoGraveVelocidadeNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Velocidade da Via", dataTipoAviso:"ExcessoVelocidadeVia", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Saida Perímetro", dataTipoAviso:"SaidaCerca", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Entrada Perímetro", dataTipoAviso:"EntradaCerca", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Sem alimentação externa", dataTipoAviso:"SemAlimentacaoExterna", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Com alimentação externa", dataTipoAviso:"ComAlimentacaoExterna", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Antena GPS Desconectada", dataTipoAviso:"AntenaGPSDesconectada", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Antena GPS Conectada", dataTipoAviso:"AntenaGPSConectada", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Acionamento Emergencia", dataTipoAviso:"AcionamentoEmergencia", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Transito Em Horario Não Permitido", dataTipoAviso:"TransitoEmHorarioNaoPermitido", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Desvio Rota", dataTipoAviso:"DesvioRota", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Aviso Ponto Intersse", dataTipoAviso:"AvisoPoI", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Aviso Saída de Ponto Interesse", dataTipoAviso:"AvisoSaidaPoI", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Movimento Indevido", dataTipoAviso:"MovimentoIndevido", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Aceleracao Ou FreadaBrusca", dataTipoAviso:"AceleracaoOuFreadaBrusca", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Aviso Veiculo Ligado", dataTipoAviso:"AvisoVeiculoLigado", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Tempo Parado Excedido", dataTipoAviso:"TempoParadoExcedido", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Jamming", dataTipoAviso:"Jamming", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Aceleração Brusca", dataTipoAviso:"AceleracaoBrusca", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Freada Brusca", dataTipoAviso:"FreadaBrusca", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Curva Acentuada", dataTipoAviso:"CurvaAcentuada", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Freada Brusca com Curva Acentuada", dataTipoAviso:"FreadaBruscaComCurvaAcentuada", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Aceleração Brusca com Curva Acentuada", dataTipoAviso:"AceleracaoBruscaComCurvaAcentuada", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Comportamento inadequado em direção", dataTipoAviso:"ComportamentoInadequado", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Alerta Ponto Morto", dataTipoAviso:"AlertaPontoMorto", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Acionamento Embreagem", dataTipoAviso:"AcionamentoEmbreagem", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Saida Ancoragem", dataTipoAviso:"SaidaPontoAncoragem", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Velocidade PoI", dataTipoAviso:"ExcessoVelocidadePoI", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Médio de Velocidade PoI", dataTipoAviso:"ExcessoMedioVelocidadePoI", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},		
	{labelTipoAviso:"Excesso Grave de Velocidade PoI", dataTipoAviso:"ExcessoGraveVelocidadePoI", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},		
      {labelTipoAviso:"Comunicação desatualizada", dataTipoAviso:"TempoSemConexao", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Tempo Direção", dataTipoAviso:"ExcessoTempoDirecao", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Acionamento do Botão de Emergência do Passageiro", dataTipoAviso:"AcionamentoEmergenciaPassageiro", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Acionamento do Botão de Emergência do Porta Malas", dataTipoAviso:"AcionamentoEmergenciaPortaMalas", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Acionamento do Botão de Emergência do Banco Traseiro", dataTipoAviso:"AcionamentoEmergenciaBancoTraseiro", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso RPM", dataTipoAviso:"ExcessoRPM", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Motorista Não Cadastrado", dataTipoAviso:"MotoristaNaoCadastrado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso RPM Faixa 4", dataTipoAviso:"ExcessoRPMFaixa4", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Pedal Acelerador Acima Limite", dataTipoAviso:"PedalAceleradorAcimaLimite", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Tempo Parado Ligado", dataTipoAviso:"ExcessoTempoParadoLigado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Motorista com Sonolencia", dataTipoAviso:"MotoristaComSonolencia", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Motorista com Alta Sonolencia", dataTipoAviso:"MotoristaComAltaSonolencia", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Desviou Atenção da Estrada", dataTipoAviso:"MotoristaDesviouAtencao", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Desviou Prolongado da Atenção da Estrada", dataTipoAviso:"MotoristaDesviouAtencaoProlongado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Uso de Cigarro pelo Motorista", dataTipoAviso:"MotoristaUsandoCigarro", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Uso de Celular pelo Motorista", dataTipoAviso:"MotoristaUsandoCelular", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Sensor Painel Violado", dataTipoAviso:"SensorPainelViolado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Sensor Painel Restaurado", dataTipoAviso:"SensorPainelRestaurado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Prancha Reboque Acionado", dataTipoAviso:"ReboqueAcionado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Prancha Reboque Desacionado", dataTipoAviso:"ReboqueDesacionado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Alerta Temperatura", dataTipoAviso:"AlertaTemperatura", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Frenagem Excessiva", dataTipoAviso:"FrenagemExcessiva", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Frenagem Excessiva Grave", dataTipoAviso:"FrenagemExcessivaGrave", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
      {labelTipoAviso:"Bateria com Carga Baixa", dataTipoAviso:"BaixaCargaNaBateria", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},   
      {labelTipoAviso:"Aviso Mensagem Teclado", dataTipoAviso:"AvisoMensagemTeclado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"}, 
      {labelTipoAviso:"Evento da Agenda ", dataTipoAviso:"AvisoAgendaEvento", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},
	{labelTipoAviso:"Atraso Evento da Agenda ", dataTipoAviso:"AvisoAtrasoAgendaEvento", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Veiculo Sem Motorista Identificado ", dataTipoAviso:"VeiculoSemMotoristaIdentificado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Motorista Não Autorizado ", dataTipoAviso:"MotoristaNaoAutorizado", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
      {labelTipoAviso:"Alarme de Colisão Frontal", dataTipoAviso:"AlarmeColisaoFrontal", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Alarme de Saída de Faixaa", dataTipoAviso:"AlarmeSaidaFaixa", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Alarme de distância curta do veículo da frente", dataTipoAviso:"AlarmeDistanciaCurtaVeiculoAFrente", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Alarme de Colisão com Pedestre", dataTipoAviso:"AlarmeColisaoPedestre", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Alarme de Mudança Frequente de Faixa", dataTipoAviso:"AlarmeMudancaFrequenteFaixa", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Alarme de velocidade acima do limite da sinalização", dataTipoAviso:"AlarmeVelocidadeAcimaDoLimitePlaca", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
	{labelTipoAviso:"Alarme de Obstaculo", dataTipoAviso:"AlarmeObstaculo", enviarEmail: "NAO", enviarSMS:"NAO", enviarCentralMonitoramento:"SIM", enviarPush:"SIM"},	
      {labelTipoAviso:"Excessode Velocidade GR - Inicio", dataTipoAviso: "ExcessoVelocidadeGRInicio", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excessode Velocidade GR - Fim", dataTipoAviso: "ExcessoVelocidadeGRFim", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excessode Velocidade na Chuva GR - Inicio", dataTipoAviso: "ExcessoVelocidadeNaChuvaGRInicio", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excessode Velocidade na Chuva GR - Fim", dataTipoAviso: "ExcessoVelocidadeNaChuvaGRFim", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
      {labelTipoAviso:"Excesso Medio VelocidadeVia", dataTipoAviso: "ExcessoMedioVelocidadeVia", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Grave VelocidadeVia", dataTipoAviso: "ExcessoGraveVelocidadeVia", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Velocidade na Via na Chuva", dataTipoAviso: "ExcessoVelocidadeViaNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Médio Velocidade na Via na Chuva", dataTipoAviso: "ExcessoMedioVelocidadeViaNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Grave Velocidade na Via na Chuva", dataTipoAviso: "ExcessoGraveVelocidadeViaNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Velocidade na Cidade", dataTipoAviso: "ExcessoVelocidadeCidade", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Medio de Velocidade na Cidade", dataTipoAviso: "ExcessoMedioVelocidadeCidade", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Grave de Velocidade na Cidade", dataTipoAviso: "ExcessoGraveVelocidadeCidade", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Velocidade na Cidade na Chuva", dataTipoAviso: "ExcessoVelocidadeCidadeNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Medio de Velocidade na Cidade na Chuva", dataTipoAviso: "ExcessoMedioVelocidadeCidadeNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
	{labelTipoAviso:"Excesso Grave de Velocidade na Cidade na Chuva", dataTipoAviso: "ExcessoGraveVelocidadeCidadeNaChuva", enviarEmail: "SIM", enviarSMS:"NAO", enviarCentralMonitoramento:"NAO", enviarPush:"SIM"},
];

export const CONSTANTE_MARCAS_ACESSORIO_MODULO = [
      {label:"AutoSender", data:"AutoSender"},
      {label:"Blue Circuits", data:"Blue_Circuits"},         
      {label:"Effortech", data:"Effortech"},
      {label:"Genérico", data:"Generico"},
      {label:"GetScale", data:"GetScale"},
      {label:"Knov", data:"Knov"},
      {label:"Ledseg", data:"Ledseg"},
      {label:"Maxtrack", data:"Maxtrack"},		
      {label:"Niatro", data:"Niatro"},
      {label:"Queclink", data:"Queclink"},
      {label:"SGBRAS", data:"SGBRAS"},
      {label:"Suntech", data:"Suntech"},
      {label:"Treearth", data:"Treearth"}       
];

export const CONSTANTE_STATE_ACESSORIO = [
      {label:"ATIVO", data:"ATIVO"},
      {label:"EM ESTOQUE", data:"EM ESTOQUE"},
      {label:"EM ASSISTENCIA", data:"EM ASSISTENCIA"},
      {label:"COM TECNICO", data:"COM TECNICO"},
      {label:"COM CLIENTE", data:"COM CLIENTE"},
      {label:"ENVIAR P/ REPARO", data:"ENVIAR P/ REPARO"},
      {label:"INATIVO", data:"INATIVO"},
      {label:"QUEBRADO", data:"QUEBRADO"},
      {label:"QUEIMADO", data:"QUEIMADO"},
      {label:"ROUBADO", data:"ROUBADO"},
      {label:"PERDIDO", data:"PERDIDO"},			 
      {label:"DE TERCEIRO", data:"DE TERCEIRO"}
];

export const CONSTANTE_TIPO_ACESSORIO_MODULO = [
      {label:"Acionamento Embreagem", data:"ACIONAMENTO_EMBREAGEM"},
      {label:"Buzzer", data:"BUZZER"},
      {label:"Dispositivo RF", data:"DISPOSITIVO_RF"},
      {label:"Generico", data:"GENERICO"},
      {label:"Identificador de Motorista", data:"IDENTIFICACAO_MOTORISTA"},
      {label:"Leitor CAN", data:"LEITOR_CAN"},
      {label:"Leitor RFID", data:"LEITOR_RFID"},
      {label:"Sensor Chuva", data:"SENSOR_CHUVA"},
      {label:"Sensor Cinto Segurança", data:"SENSOR_CINTO_SEGURANCA"},
      {label:"Sensor Tampa Tanque Combustível", data:"SENSOR_TAMPA_TANQUE_COMBUSTIVEL"},
      {label:"Sensor Porta", data:"SENSOR_PORTA"},
      {label:"Sensor Temperatura", data:"SENSOR_TEMPERATURA"},
      {label:"Sensor Simples", data:"SENSOR_SIMPLES"},		
      {label:"Teclado", data:"TECLADO"},
      {label:"Trava de Baú", data:"TRAVA_BAU"}		
];

export const CONSTANTE_TIPO_CONFIGURACAO_ACESSORIO_MODULO = [
	{label:"Ler os 8 ultimos digitos e inverter", data:"LER_8_ULTIMOS_E_INVERTER"},
	{label:"Ler todos os digitos e inverter", data:"LER_TODOS_E_INVERTER"}
];

export const CONSTANTE_TIPO_CONFIGURACAO_GERAL_ACESSORIO_MODULO = [
	{label:"Normal", data:"NORMAL"},
	{label:"Invertido", data:"INVERTIDO"}
];

export const CONSTANTE_TIPOS_COMANDOS_TCP = [
      {label:"Ajustar Tolerância Parado (segundos)", data:"SPC_GSR_DEB_STOPPED", modulos:"Maxtrack"},
      {label:"Ajustar Tolerância Movendo ( segundos )", data:"SPC_GSR_DEB_MOVING", modulos:"Maxtrack"},
      {label:"Ajustar Pulsos Hodômetro", data:"SPC_SET_ODOMETRO_PULSES", modulos:"Maxtrack"},
      {label:"Ajustar Valor Odômetro", data:"SPC_SET_ODOMETER", modulos:"Maxtrack, Continental"},
      {label:"Ajustar Valor Horímetro", data:"SPC_SET_HOURMETER", modulos:"Maxtrack"},
      {label:"Ajustar Valor Horímetro", data:"SET_HOURMETER_VALUE", modulos:"Continental"},
      {label:"Ajustar Valor Odômetro", data:"SET_ODOMETER", modulos:"Calamp"},
      {label:"Ajustar Valor Horímetro", data:"SET_HOURMETER", modulos:"Calamp"},
      {label:"Ativar saída 2", data:"SPC_OUTPUT2_ACTIVATE", modulos:"Maxtrack"},
      {label:"Ativar saída 2 Intermitente", data:"SPC_SET_SMART_OUTPUT2", modulos:"Maxtrack"},
      {label:"Ativar saída 2 - Limite Velocidade", data:"SPC_SET_EXCEEDSPEED_OUTPUT", modulos:"Maxtrack"},
      {label:"Ativar Escuta", data:"GPRS_PAUSE", modulos:"Maxtrack"},
      {label:"Configurar GPS", data:"CONFIGURAR_GPS", modulos:"Maxtrack"},
      {label:"Definir limite velocidade", data:"SPC_MAX_SPEED_LIMIT", modulos:"Maxtrack"},
      {label:"Definir Pulsos RPM", data:"SPC_SET_RPM_PULSES", modulos:"Maxtrack"},
      {label:"Desativar saída 2", data:"SPC_OUTPUT2_DESACTIVATE", modulos:"Maxtrack"},
      {label:"Desativar enviar imediatamente", data:"SPC_GSR_REPORT", modulos:"Maxtrack"},
      {label:"Desabilitar Info RPM", data:"SPC_SET_POSITION_GROUP_1C", modulos:"Maxtrack"},
      {label:"Desabilitar Botão de pânico", data:"SPC_PANIC_MODE_DISABLE", modulos:"Maxtrack"},
      {label:"Desabiliar/Habilitar Entrada Odometro/RPM ", data:"SPC_SET_ODORPM_ENABLE", modulos:"Maxtrack"},
      {label:"Desligar", data:"POWEROFF", modulos:"Maxtrack"},
      {label:"Desligar acionamento do pânico", data:"DEACTIVATE_PANIC", modulos:"Maxtrack"},      
      {label:"Habilitar cálculo de odometro ign off", data:"SPC_CALC_ODOMETER", modulos:"Maxtrack"},
      {label:"Habilitar Botão de pânico", data:"SPC_PANIC_MODE_ENABLE", modulos:"Maxtrack"},
      {label:"Habilitar Entrada RPM (passo2)", data:"SPC_SET_ODORPM_ENABLE", modulos:"Maxtrack"},		
      {label:"Habilitar Info RPM (passo1)", data:"SPC_SET_POSITION_GROUP_1E", modulos:"Maxtrack"},
      {label:"GMT Concox", data:"GMT", modulos:"GT"},
      {label:"Hearbeat Concox", data:"HBT", modulos:"GT"},
      {label:"Intervalo de atualização ( segundos )", data:"TIMER", modulos:"GT"},	
      {label:"Ajustar Valor Odômetro", data:"MILEAGE", modulos:"GT"},
      {label:"Reiniciar", data:"RESET_GT", modulos:"GT"},	
      {label:"GMT J16", data:"GMT2", modulos:"GT"},
      {label:"Habilitar Ignição Física", data:"IGNICAO_FISICA", modulos:"GT"},
      {label:"Habilitar Voltagem Bateria", data:"HABILTAR_VOLTAGEM_BATERIA", modulos:"GT"},
      {label:"Habilitar Protocolo V3", data:"HABILITAR_PROTOCOLO_V3", modulos:"GT"},
      {label:"Habilitar Odometro em Metros", data:"HABILITAR_ODOMETRO_EM_METROS", modulos:"GT"},            
      {label:"Comando Genérico", data:"COMANDO_GENERICO_GT", modulos:"GT"},
      {label:"Intervalo de atualização ( segundos )", data:"APPLICATION_TIMING_PARAMETERS", modulos:"Continental"},		
      {label:"Limpar log de pacotes antigos", data:"CLEAR_OLD_REPORT", modulos:"Maxtrack"},
      {label:"Obter Pacotes Antigos", data:"GET_OLD_POSITIONS_PACKET", modulos:"Maxtrack"},
      {label:"Reiniciar", data:"RESET", modulos:"Maxtrack"},
      {label:"Retirar info de celular", data:"SPC_CELL_INFO_PRESET", modulos:"Maxtrack"},
      {label:"Setar grupo de informações 1C", data:"SPC_SET_POSITION_GROUP_1C", modulos:"Maxtrack"},
      {label:"Setar grupo de informações 1E", data:"SPC_SET_POSITION_GROUP_1E", modulos:"Maxtrack"},
      {label:"Setar grupo de informações 3C", data:"SPC_SET_POSITION_GROUP_3C", modulos:"Maxtrack"},
      {label:"Setar tempo de sleep para x h", data:"SPC_KEEP_WORKING_BF_SLEEP", modulos:"Maxtrack"},
      {label:"Setar tempo de sleep para 24h", data:"SPC_KEEP_WORKING_BF_SLEEP_24H", modulos:"Maxtrack"},
      {label:"Setar tempo de envio parado", data:"SPC_RI_STOPPED", modulos:"Maxtrack"},
      {label:"Setar APN concept.vivo.com.br", data: "PARAMETERS_OF_TELEPHONE_OPERATORS", modulos:"Continental"},
      {label:"Tempo para detectar falha GPRS", data:"BIN_PARAM_TEMPO_PARA_FALHA_GPRS", modulos:"MaxtrackMTC"},
      {label:"Ativar saída 1", data:"SPC_OUTPUT1_ACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Ativar saída 2", data:"SPC_OUTPUT2_ACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Ativar saída 3", data:"SPC_OUTPUT3_ACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Ativar saída 4", data:"SPC_OUTPUT4_ACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Desativar saída 1", data:"SPC_OUTPUT1_DESACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Desativar saída 2", data:"SPC_OUTPUT2_DESACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Desativar saída 3", data:"SPC_OUTPUT3_DESACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Desativar saída 4", data:"SPC_OUTPUT4_DESACTIVATE_MTC", modulos:"MaxtrackMTC"},
      {label:"Desligar acionamento do pânico", data:"DEACTIVATE_PANIC_MTC", modulos:"MaxtrackMTC"},		
      {label:"Set tempo envio ligado Sat", data:"BIN_PARAM_TEMPO_TRANS_IGN_SAT", modulos:"MaxtrackMTC"},
      {label:"Set tempo envio panico Sat", data:"BIN_PARAM_TEMPO_TRANS_PAN_SAT", modulos:"MaxtrackMTC"},
      {label:"Set tempo envio desligado Sat", data:"BIN_PARAM_TEMPO_TRANS_IGN_OFF", modulos:"MaxtrackMTC"},		
      {label:"Ajustar Valor Odômetro", data:"SETODOMETER", modulos:"Suntech"},
      {label:"Ajustar Valor Horímetro", data:"SETHMETER", modulos:"Suntech"},
      {label:"Ativar saída 1", data:"ENABLE1", modulos:"Suntech"},
      {label:"Ativar saída 2", data:"ENABLE2", modulos:"Suntech"},
      {label:"Desativar saída 1", data:"DISABLE1", modulos:"Suntech"},
      {label:"Desativar saída 2", data:"DISABLE2", modulos:"Suntech"},
      {label:"Limpar Memória", data:"ERASEALL", modulos:"Suntech"},
      {label:"Parâmetros de Envio", data:"REPORTING", modulos:"Suntech"},
      {label:"Nova Configuração de Parâmetros", data:"NEW_PARAMETER_SETTING", modulos:"Suntech"},
      {label:"Reiniciar", data:"REBOOT", modulos:"Suntech"},
      {label:"Ativar Eventos Acelerometro", data:"SPC_SET_EVENT_FLAG", modulos:"Maxtrack"},
      {label:"Configura Acelerometro Automatico", data:"SPC_GSR_RS_MODE", modulos:"Maxtrack"},
      {label:"Ativar Eventos Acelerometro", data:"SET_ACCELEROMETER_PARAMETERS", modulos:"Continental"},
      {label:"Ativar saída 1", data:"GTOUT_ENABLE", modulos:"Queclink"},
      {label:"Desativar saída 1", data:"GTOUT_DISABLE", modulos:"Queclink"},
      {label:"Ativar saída 2", data:"GTOUT_2_ENABLE", modulos:"Queclink"},
      {label:"Desativar saída 2", data:"GTOUT_2_DISABLE", modulos:"Queclink"},
      {label:"Ativar saída 1", data:"TURN_OFF_CAR_OIL", modulos:"CCTR"},
      {label:"Desativar saída 1", data:"TURN_ON_CAR_OIL", modulos:"CCTR"},
      {label:"Ativar saída 1", data:"POWER_CONTROL_OFF", modulos:"E3"},
      {label:"Desativar saída 1", data:"POWER_CONTROL_ON", modulos:"E3"},
      {label:"Ajustar Valor Odômetro", data:"MILEAGE_CORRECTION", modulos:"E3"},
      {label:"Ajustar Valor Odômetro", data:"GT_SETODOMETER", modulos:"Queclink"},
      {label:"Ajustar Valor Horímetro", data:"GT_SETHOURMETER", modulos:"Queclink"},
      {label:"Habilitar Entrada 1", data:"HABILITAR_ENTRADA1", modulos:"Queclink"},
      {label:"Habilitar Botão de Pânico", data:"HABILITAR_BOTAO_PANICO", modulos:"Queclink"},
      {label:"Habilitar Horímetro", data:"HABILITAR_HORIMETRO", modulos:"Queclink"},
      {label:"Desabilitar Entrada 1", data:"DESABILITAR_ENTRADA1", modulos:"Queclink"},
      {label:"Desabilitar Botão de Pânico", data:"DESABILITAR_BOTAO_PANICO", modulos:"Queclink"},
      {label:"Desabilitar Horímetro", data:"DESABILITAR_HORIMETRO", modulos:"Queclink"},
      {label:"Cadastrar Cartão ID", data:"GTIDA_ENABLE", modulos:"Queclink"},
      {label:"Remover Todos Cartão ID", data:"GTIDA_REMOVE", modulos:"Queclink"},
      {label:"Desabilitar Identificação Cartão", data:"GTIDA_DISABLE", modulos:"Queclink"},
      {label:"Desabilitar Leitora de Cartão", data:"GTURT_NORMAL", modulos:"Queclink"},
      {label:"Habilitar Leitora de Cartão", data:"GTURT_LEITOR_CARTAO", modulos:"Queclink"},
      {label:"Listar Cartões Habilitados", data:"GTRTO", modulos:"Queclink"},
      {label:"Liberar/Bloquear qualquer Wt110", data:"SPC_ALLOW_ANY_TAG_ACC", modulos:"Maxtrack"},
      {label:"Alterar Chave de Criptografia", data:"SPC_ZIG_ACC_ENCRYPT_KEY", modulos:"Maxtrack"},      
      {label:"Comando Genérico", data:"COMANDO_GENERICO", modulos:"Queclink"},
      {label:"Comando Genérico", data:"COMANDO_GENERICO_ST", modulos:"Suntech"},      
      {label:"Reiniciar", data:"GT_REBOOT", modulos:"Queclink"},
      {label:"Desligar", data:"GT_POWEROFF", modulos:"Queclink"},
      {label:"Requisitar Posição por SMS", data:"GT_REQUEST_POSITION", modulos:"Queclink"},
      {label:"Ajustar Velocidade e Sleep", data:"PARAMETER_VARIOUS_FEATURES", modulos:"Suntech"},
      {label:"Solicitar ICCID", data:"REQ_ICCID", modulos:"Suntech"},
      {label:"Ativar saída 1", data:"SETDIGO_OUT1_ON", modulos:"Teltonika"},
      {label:"Desativar saída 1", data:"SETDIGO_OUT1_OFF", modulos:"Teltonika"},
      {label:"Ativar saída 2", data:"SETDIGO_OUT2_ON", modulos:"Teltonika"},
      {label:"Desativar saída 2", data:"SETDIGO_OUT2_OFF", modulos:"Teltonika"},
      {label:"Comando Genérico", data:"COMANDO_GENERICO_TELTONIKA", modulos:"Teltonika"},
      {label:"Ajustar Valor Odômetro", data:"SETPARAM_ODOMETRO", modulos:"Teltonika"},
      {label:"Reboot", data:"CPURESET", modulos:"Teltonika"},
      {label:"Solicitar ICCID", data:"GET_ICCID", modulos:"Maxtrack"},
      {label:"Ativar saída 1", data:"XRLYE_1", modulos:"GlobalPosition"},
      {label:"Desativar saída 1", data:"XRLYE_0", modulos:"GlobalPosition"},
      {label:"Comando Genérico", data:"COMANDO_GENERICO_GLOBALPOSITION", modulos:"GlobalPosition"},
      {label:"Solicitar ICCID", data:"GTCID", modulos:"Queclink"},
      {label:"Reboot", data:"E3_REBOOT", modulos:"E3"},
      {label:"Solicitar ICCID", data:"E3_GET_ICCID", modulos:"E3"},
      {label:"Tempo de Comunicação", data:"E3_TEMPO_COMUNICACAO", modulos:"E3"},
      {label:"Comando Genérico", data:"E3_COMANDO_GENERICO", modulos:"E3"},
      {label:"Enviar comando pela RS232", data:"SEND_MESSAGE_RS232", modulos:"Suntech"}, 
      {label:"Habilitar Analise de Motorista", data:"DPA_ENABLE", modulos:"Suntech"},     
      {label:"Desabilitar Analise de Motorista", data:"DPA_DISABLE", modulos:"Suntech"},     
      {label:"Habilitar Identificador de Motorista - Saida 1", data:"HTP_ENABLE_SAIDA1", modulos:"Suntech"},    
      {label:"Habilitar Identificador de Motorista - Saida 2", data:"HTP_ENABLE_SAIDA2", modulos:"Suntech"},    
      {label:"Desabilitar Identificador de Motorista", data:"HTP_DISABLE", modulos:"Suntech"},    
];

export const CONSTANTE_LOCAL_INSTALACAO_ACESSORIO_MODULO = 
[
      {label:"ENTRADA 1", data:"ENTRADA1"},
      {label:"ENTRADA 2", data:"ENTRADA2"},
      {label:"ENTRADA 3", data:"ENTRADA3"},		
      {label:"ENTRADA 4", data:"ENTRADA4"},		
      {label:"IDENTIFICADOR DE MOTORISTA", data:"IDENTIFICADOR_MOTORISTA"},
      {label:"SENSOR RS232", data:"SENSOR_RS232"},
      {label:"SENSOR 1-WIRE", data:"SENSOR_1WIRE"},
      //{label:"SAIDA 1", data:"SAIDA1"},
      {label:"SAIDA 2", data:"SAIDA2"},
      {label:"SAIDA 3", data:"SAIDA3"},
      {label:"TECLADO", data:"TECLADO"},
      {label:"TELEMETRIA", data:"TELEMETRIA"},
      {label:"ODOMETRO", data:"ODOMETRO"},
      {label:"RPM", data:"RPM"}
];

export const CONSTANTE_TIPOS_AVISO_AUX =  [      
      {label:"Capô", data:"AberturaCapo"},
      {label:"Porta-Malas", data:"AberturaPortaMalas"},
      {label:"Porta", data:"AberturaPorta"},
      {label:"Porta Motorista", data:"AberturaPortaMotorista"},
      {label:"Porta Carona", data:"AberturaPortaCarona"},
      {label:"Porta Baú Traseira", data:"AberturaPortaBauTraseira"},
      {label:"Porta Baú Lateral", data:"AberturaPortaBauLateral"},
      {label:"Cofre", data:"AberturaCofre"},
      {label:"Acionamento Engate", data:"AcionamentoEngate"},
      {label:"Acionamento Prancha", data:"ReboqueAcionado"},
      {label:"Acionamento Caçamba", data:"AcionamentoCacamba"},
      {label:"Acionamento Embreagem", data:"AcionamentoEmbreagem"},
      {label:"Acionamento do Botão de Emergência do Passageiro", data:"AcionamentoEmergenciaPassageiro"},
      {label:"Acionamento do Botão de Emergência do Banco Traseiro", data:"AcionamentoEmergenciaBancoTraseiro"},
      {label:"Câmara Fria", data:"CamaraFriaLigada"},
      {label:"Limpador de Para-brisa", data:"LimpadorParabrisasLigado"},
      {label:"Tampa de Combustível", data:"FechamentoTanqueCombustivel"},
      {label:"Tampa de Combustível Tanque 1", data:"FechamentoTanque1Combustivel"},
      {label:"Tampa de Combustível Tanque 2", data:"FechamentoTanque2Combustivel"},
      {label:"Sensor de Painel", data:"SensorPainelRestaurado"},
      {label:"Generico Entrada 1", data:"AvisoAux0"},
      {label:"Generico Entrada 2", data:"AvisoAux1"},
      {label:"Generico Entrada 3", data:"AvisoAux2"},
      {label:"Generico Entrada 4", data:"AvisoAux3"}
];

export const CONSTANTE_TIPOS_SENSOR_RS232 = [
      {label:"Identificador de Motorista SGBRAS", data:"LeitorRFIDSGBRAS"},
      {label:"I-VUE - Monitoramento para Motorista", data:"DMSTreeArthIVUE"},
      {label:"SE3 - Monitoramento para Motorista", data:"DMSTreeArthSE3"},
      {label:"Identificador de Motorista Knov KN01", data:"LeitorRFIDKnovKN01ParaIdentificarMotorista"},		
      {label:"Identificador Biometrico de Motorista Effortech", data:"LeitorEffortechBioID"},		
      {label:"Leitor RFID Knov KN01", data:"LeitorRFIDKnovKN01"},
      {label:"Leitor CAN Blue Circuits TDI", data:"LeitorCANTDI"},
      {label:"Sensor Betoneira Autosender", data:"SensorBetoneiraAutosender"},
      {label:"Sensor Temperatura e Umidade Getscale", data:"TransmissorTemperaturaUmidadeGetScale"},
      {label:"Sensor Temperatura Niatro", data:"TransmissorTemperatura4SensoresNiatro"},      
      {label:"Sensor Temperatura Queclink", data:"TransmissorTemperaturaQueclink"},   
      {label:"Sensor Temperatura Dallas Autosender", data:"TransmissorTemperaturaDallasAutosender"},            
      {label:"Teclado RFID KNOV KN02", data:"TecladoRFIDKNOVKN02"},
      {label:"Teclado RFID KNOV KN02 hexa", data:"TecladoRFIDKNOVKN02EmHexa"},
      {label:"Teclado RFID GetScale", data:"TecladoGetScale"},
      {label:"Teclado RFID SGBRAS", data:"TecladoRFIDSGBRAS"},
      {label:"Triplicador Niatron com 2 Teclados RFID GetScale", data:"TriplicadorNiatronRS232_TecladoGetScale_TecladoGetScale"},      
];

export const CONSTANTE_TIPO_CONTRATO_VEICULO = [	
      {label:"COMODATO", data:"COMODATO"},
      {label:"VENDA", data:"VENDA"},
      {label:"EQUIPAMENTO DO CLIENTE", data:"EQUIPAMENTO_CLIENTE"},
];

export const CONSTANTE_TIPOS_CONTRATOS = [
      {label:"Comodato - Novo Cliente", data:"NovoCliente"},
      {label:"Comodato - Renovação Contrato", data:"Renovacao"}];

export const CONSTANTE_ORIGEM_DADOS = [
      {label:"GPS", data:"GPS"},
      {label:"CAN", data:"CAN"},
      {label:"AMBOS", data:"AMBOS"},
];

export const CONSTANTE_STATUS_OS = [
      {label:"ABERTA", data:"ABERTA"},
      {label:"PENDENTE", data:"PENDENTE"},
      {label:"CANCELADA", data:"CANCELADA"},
      {label:"EXECUTADA", data:"EXECUTADA"},
      {label:"FINALIZADA", data:"FINALIZADA"}
];

export const CONSTANTE_TIPO_OS =  [   
	{label:"INSTALAÇÃO NOVO VEÍCULO", data:"INSTALACAO"},
	{label:"INSTALAÇÃO ACESSÓRIO", data:"INSTALACAO_ACESSORIO"},
	{label:"DESINSTALAÇÃO", data:"DESINSTALACAO"},
	{label:"VERIFICAÇÃO", data:"VERIFICACAO"},
	{label:"SUBSTITUIÇÃO", data:"SUBSTITUICAO"}
];

export const CONSTANTE_PERFIL_USUARIO =  [   
	{label:"ADMINISTRADOR", data:"ADMINISTRADOR"},
      {label:"ATIVADOR", data:"ATIVADOR"},
      {label:"MONITOR_PANICO", data:"MONITOR_PANICO"},
      {label:"SUPERVISOR", data:"SUPERVISOR"},
      {label:"CONFIGURADOR", data:"CONFIGURADOR"},
      {label:"FINANCEIRO", data:"FINANCEIRO"},	
      {label:"TECNICO", data:"TECNICO"},
      {label:"COMERCIAL", data:"COMERCIAL"},
      {label:"EMPRESA", data:"EMPRESA"},
      {label:"EMPRESA MONITOR PANICO", data:"EMPRESA_MONITOR_PANICO"},
      {label:"EMPRESA SUPERVISOR", data:"EMPRESA_SUPERVISOR"},
      {label:"EMPRESA ATIVADOR", data:"EMPRESA_ATIVADOR"},
      {label:"EMPRESA SUPORTE INTERNO", data:"EMPRESA_SUPORTE_INTERNO"},
      {label:"EMPRESA SUPORTE INTERNO CONCEPT", data:"EMPRESA_SUPORTE_INTERNO_CONCEPT"},
      {label:"COMERCIAL", data:"EMPRESA_COMERCIAL"},
      
];

export const CONSTANTE_PERFIL_USUARIO_PERFIL_SUPERVISOR =  [   	
      {label:"ATIVADOR", data:"ATIVADOR"},
      {label:"MONITOR_PANICO", data:"MONITOR_PANICO"},      
      {label:"CONFIGURADOR", data:"CONFIGURADOR"},      
      {label:"TECNICO", data:"TECNICO"},
      {label:"COMERCIAL", data:"COMERCIAL"},      
      {label:"EMPRESA MONITOR PANICO", data:"EMPRESA_MONITOR_PANICO"},
      {label:"EMPRESA SUPERVISOR", data:"EMPRESA_SUPERVISOR"},
      {label:"EMPRESA ATIVADOR", data:"EMPRESA_ATIVADOR"},
      {label:"EMPRESA SUPORTE INTERNO", data:"EMPRESA_SUPORTE_INTERNO"},
      {label:"EMPRESA SUPORTE INTERNO CONCEPT", data:"EMPRESA_SUPORTE_INTERNO_CONCEPT"}
];

export const CONSTANTE_PERFIL_USUARIO_CENTRAL =  [   
	{label:"ADMINISTRADOR", data:"EMPRESA"},
      {label:"MONITOR PANICO", data:"EMPRESA_MONITOR_PANICO"},
      {label:"SUPERVISOR", data:"EMPRESA_SUPERVISOR"},
      {label:"ATIVADOR", data:"EMPRESA_ATIVADOR"},      
];

export const CONSTANTE_TIPOS_TECLADOS = [
	{label:"Maxtrack TD 50", data:"Maxtrack"},
	{label:"KNOV KN02", data:"TecladoRFIDKNOVKN02"},
	{label:"Teclado Get Scale", data:"TecladoGetScale"}
];

export const CONSTANTE_STATUS_PRE_CADASTRO = [
      {label:"RASCUNHO", data:"RASCUNHO"},
      {label:"EM ANÁLISE", data:"EM ANÁLISE"},
      {label:"APROVADO", data:"APROVADO"},
      {label:"REPROVADO", data:"REPROVADO"}
];

export const CONSTANTE_OPCAO_SIM_NAO = [	
	{label:"SIM", data:"SIM"},			
	{label:"NÃO", data:"NAO"}
];

export const CONSTANTE_REGRA_FATURAMENTO_CLIENTE = [
      {label:"No vencimento", data:"NO_VENCIMENTO"},
      {label:"01 de cada mês até o ultimo dia do mês", data:"DIA_01_ATE_ULTIMO_DIA"},
      {label:"05 de cada mês até o dia 4 do mês seguinte", data:"DIA_05_ATE_DIA_04_MES_SEGUINTE"},
      {label:"10 de cada mês até o dia 9 do mês seguinte", data:"DIA_10_ATE_DIA_09_MES_SEGUINTE"},
      {label:"15 de cada mês até o dia 14 do mês seguinte", data:"DIA_15_ATE_DIA_14_MES_SEGUINTE"},
      {label:"20 de cada mês até o dia 19 do mês seguinte", data:"DIA_20_ATE_DIA_19_MES_SEGUINTE"},
      {label:"25 de cada mês até o dia 24 do mês seguinte", data:"DIA_25_ATE_DIA_24_MES_SEGUINTE"},
];